import React, { useState } from "react"
import styled from "styled-components"

import { GlassBox } from "./glassBox"
import { Box } from "./box"
// import { Text } from "./text"
import { Heading as HeadingG } from "./heading"

const Heading = styled(HeadingG)`
  text-decoration: ${props => props.textDecoration};
  text-underline-offset: 0.8rem;
`

const AccordionItem = styled(GlassBox)`
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const AccordionTitle = styled(Box)`
  cursor: pointer;
`

// const AccordionContent = styled(Box)``

export const Accordion = ({
  title,
  children,
  initialState = true,
  contentHeight = "auto",
  titleSelected = 0,
  onTitleClicked = () => {},
  isPopUp = false,
  ...props
}) => {
  const [isActive, setIsActive] = useState(initialState)

  return (
    <AccordionItem alignItems="stretch" {...props}>
      {title && (
        <AccordionTitle
          onClick={() => {
            if (!isPopUp) {
              setIsActive(!isActive)
            }
          }}
          flexDirection="row"
          justifyContent="space-between"
          pb="1.3rem"
        >
          {Array.isArray(title) ? (
            <Box flexDirection="row" gap="1.5rem">
              {title.map((item, index) => (
                <Heading
                  level={4}
                  mb="0rem"
                  textDecoration={
                    item.selected && isActive ? "underline" : "none"
                  }
                  onClick={() => onTitleClicked(index)}
                >
                  {item.title}
                </Heading>
              ))}
            </Box>
          ) : (
            <Heading level={4} mb="0rem">
              {title}
            </Heading>
          )}
          {!isPopUp && (
            <Heading level={4} mb="0rem">
              {isActive ? "-" : "+"}
            </Heading>
          )}
        </AccordionTitle>
      )}
      {isActive && (
        <Box alignItems="stretch" minHeight={contentHeight}>
          {children}
        </Box>
      )}
    </AccordionItem>
  )
}
